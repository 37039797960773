import { createBrowserRouter} from "react-router-dom";
import Layout from '../../src/pages/Layout'
import New from '../../src/pages/New'
import Month from '../../src/pages/Month'
import Year from '../../src/pages/Year'


const router=createBrowserRouter([
    {
        'path':'/',
        'element':<Layout/>,
        children:[
            {
                'path':'year',
                'element':<Year></Year>
            },
            {
                'path':'month',
                'element':<Month></Month>
            }
        ]
    },
    {
        'path':'/New',
        'element':<New></New>
    }
])
export default router
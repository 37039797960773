// 账单列表 store
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const billStore= createSlice({
    name:'bill',  
    // 定义数据状态
    initialState:{
        billList:[]
    },
    // 定义数据修改方法
    reducers:{
        // 同步修改的方法
        setBillList(state,action){
            state.billList=action.payload;
        }
    }
})
// 解构actionCreater函数
const {setBillList} =billStore.actions;
//编写异步请求
const getBillList=()=>{
    return async (dispatch)=>{
        // 编写异步请求
        const res=await axios.get('http://localhost:3004/ka');
        // 触发同步修改
        dispatch(setBillList(res.data));
    }
}

export {getBillList}
// 导出reducer
const reducer=billStore.reducer;
export default reducer;
import { Button } from "antd-mobile"
import {  Outlet } from "react-router-dom"

import { getBillList } from '@/store/modules/billStore'
import { useDispatch,useSelector } from 'react-redux';
import { useEffect } from 'react';


const Layout=()=>{  
    console.log("试试")
    const despath= useDispatch();
    const {billList}= useSelector(state=>state.bill)
    useEffect(()=>{
      despath(getBillList());
    },[despath])   
    console.log("--",billList)

    return (
        <div>
            <Outlet></Outlet>
            <div>主页</div>
            <Button color="primary">我是一个小按钮</Button>
            <div className="puple">
                <Button color="primary">我是一个小按钮</Button>
            </div>
        </div>
    )
}
export default Layout